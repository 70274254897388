// Constants used in entire website

// Livorn URL Play Store
export const playStoreUrl =
  "https://play.google.com/store/apps/details?id=company.cubes.livorn&hl=en&gl=US";

// Whatsapp Url
export const whatsappCommunityUrl = "https://chat.whatsapp.com/KjVCXDIu3Ym2Kt2xtQyIvT";

// Contact Email on the website, later change to contact@cubes.company
export const contactEmail = "contact@livorn.online";

// Is Mobile Device Check
export const isMobileDevice = window.innerWidth <= 768;

// Is Tab Device
export const isTabDevice = window.innerWidth >= 768 && window.innerWidth <= 1024;

// Livorn Video Url
export const videoUrl =
  "https://firebasestorage.googleapis.com/v0/b/livorn-website.appspot.com/o/livornVideo.mp4?alt=media&token=e39589db-5e76-4957-8859-8d8fc5c276a6";

// Support Whatsapp Url
export const whatsappSupportUrl =
  "https://api.whatsapp.com/send/?phone=919588795279&text=Hello%2C%20I%20need%20premium%20of%20Livorn";
